import React, {useState, useEffect} from "react";
import Header from "../componant/HeaderNew";
import {Button} from "@mui/material";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
} from "@mui/material";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
import Footer from "../Footer";
import {Helmet} from "react-helmet";
// import Bgvedio from "../Image/globe-fiat-block.mp4";

import FAQAccordion from "../componant/FAQAccordionProps";

function Home() {
  const [isScrolled, setIsScrolled] = useState(false);
  // useEffect(() => {
  //   function handleScroll() {
  //     const fourthSection = document.querySelector("#section-4");
  //     const fourthSectionTop = fourthSection.offsetTop;
  //     const currentScrollPosition = window.pageYOffset + window.innerHeight;
  //     if (currentScrollPosition >= fourthSectionTop) {
  //       setIsScrolledToFourthSection(true);
  //     } else {
  //       setIsScrolledToFourthSection(false);
  //     }
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  useEffect(() => {
    function handleScroll() {
      const fourthSection = document.querySelector("#section-4");
      const header = document.querySelector("header");
      const {top} = fourthSection.getBoundingClientRect();
      const headerHeight = header.offsetHeight;
      setIsScrolled(top <= headerHeight);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const faqs = [
    {
      question: "What is a Digital Signature?",
      answer:
        "A Digital Signature is an electronic form of a signature that verifies the authenticity and integrity of a digital document. It provides a secure and legally binding way to sign documents electronically, ensuring that the signer is who they claim to be and that the document has not been altered.",
    },
    {
      question: "How does Law Blocks AI ensure the security of my documents?",
      answer:
        "Law Blocks AI ensures the security of your documents through end-to-end encryption, multi-factor authentication, and an audit trail. End-to-end encryption protects your data from unauthorized access, while multi-factor authentication verifies the identity of each signer. The audit trail provides a comprehensive record of the signing process, ensuring transparency and accountability.",
    },
    {
      question: "Is it easy to upload documents on Law Blocks AI?",
      answer:
        "Yes, the process is straightforward and user-friendly, ensuring even those with minimal technical expertise can easily upload documents.",
    },
    {
      question: "How secure are the documents stored on the blockchain?",
      answer:
        "Documents are encrypted and stored with an immutable record, ensuring they are tamper-proof and secure.",
    },
    {
      question: "Can I access my documents anytime?",
      answer:
        "Yes, documents stored on Law Blocks AI can be accessed quickly and efficiently, from anywhere in the world.",
    },
    {
      question: "What types of documents can be stored?",
      answer:
        "Law Blocks AI supports various file formats, making it suitable for legal contracts, medical records, financial documents, and more.",
    },
    {
      question: "Is there customer support available?",
      answer:
        "Yes, Law Blocks AI offers comprehensive support to assist with any questions or issues.",
    },
  ];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const navigate = () => {
    window.open("https://market.lawblocks.io/login", "_blank");
  };

  return (
    <div className={isScrolled ? "header scrolled" : "header color_white"}>
      <Header />
      <Helmet>
        <title>
          Digital Signature with Law Blocks AI - Secure, Fast, and Reliable
        </title>
        <meta
          property="og:title"
          content="Digital Signature with Law Blocks AI - Secure, Fast, and Reliable"
        />
        <meta
          property="og:description"
          content="Sign securely and smartly with Law Blocks AI. Our Digital Signature solution provides a secure, efficient, and legally binding way to sign documents online. With features like end-to-end encryption, multi-factor authentication, and an audit trail, Law Blocks AI ensures the highest level of security for your documents."
        />
        {/* <meta
  property="og:image"
  content="https://lawblocks.io/assets/digital-signature-thumbnail.jpg"
/> */}
        <meta
          property="og:url"
          content="https://lawblocks.io/digital-signature"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Digital Signature with Law Blocks AI - Secure, Fast, and Reliable"
        />
        <meta
          name="twitter:description"
          content="Sign securely and smartly with Law Blocks AI. Our Digital Signature solution provides a secure, efficient, and legally binding way to sign documents online. Experience the future of digital signing with Law Blocks AI."
        />
        {/* <meta
  name="twitter:image"
  content="https://lawblocks.io/assets/digital-signature-thumbnail.jpg"
/> */}
      </Helmet>
      <main>
        {/* <SectionWithVideoBackground /> */}
        <section
          className="section_banner_hero new_Section_AIdoc uploaddocs bg-white didgtal_hero"
          id="section-4"
        >
          {/* <div className="purble"></div>
          <div className="yellow"></div>
          <div className="green"></div> */}

          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={6}>
                <div className="hero_seaction_law align-items-start ">
                  <h1
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-left mb-2"
                  >
                    Smart, Secure Digital Signature Anywhere with Law Blocks AI
                  </h1>

                  <p
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-left m-0"
                  >
                    Law Blocks AI makes your signing process easy and fast. Sign
                    anywhere, anytime.
                  </p>
                  <Button
                  onClick={navigate}
                    className="laight_button border_radious_button"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                  >
                    Try for free
                  </Button>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={6}
                className="d-flex align-items-center justify-content-center"
              ></Grid>
            </Grid>
          </Container>
        </section>

        <section className="section_defualt  bg-gray_section">
          <Container maxWidth="lg">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="imag_widtjas image_contain_digital"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <img
                      src={require("../../Image/banner/overover-view.webp")}
                      className="img-fluid"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="overvide align-items-start"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2>Overview of Law Blocks AI Digital Signature</h2>
                    <p>
                      A digital signature is an electronic method of signing
                      documents, providing a secure and efficient alternative to
                      traditional pen-and-paper signatures. Using advanced
                      encryption technology, digital signatures ensure the
                      authenticity and integrity of documents by confirming the
                      signer's identity and protecting against tampering. Ideal
                      for legal agreements, contracts, and other important
                      documents, digital signatures streamline the signing
                      process, making it fast, reliable, and accessible from
                      anywhere in the world.
                    </p>

                    <Button
                      onClick={navigate}
                      className="laight_button border_radious_button"
                    >
                      Get Started
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section
          className="section_defualt "
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={8}>
                <div
                  className="overvide align-items-center"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <h2 className="text-left">
                    Streamlined Document Signing Process with Digital Signature
                  </h2>
                  <p>
                    Law Blocks AI’s Digital Signature software is designed with
                    user convenience in mind. The platform offers three
                    different ways to sign your documents, making the process
                    adaptable to your preferences
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={4}>
                <div
                  className="imag_widtjas image_contain_digital"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <img
                    src={require("../../Image/banner/step_sign.webp")}
                    className="img-fluid"
                  />
                </div>
              </Grid>
            </Grid>
            <div class="features-container pl-0 pr-0">
              <div class="feature-box ml-0">
                <div className="circule_radioue">
                  <img
                    src={require("../../Image/banner/upload-s.webp")}
                    alt=""
                  />
                </div>
                <h3>Upload Your Signature</h3>
                <p>
                   Have a scanned version of your handwritten signature? You can
                  easily upload it in PNG or JPG format, giving your digital
                  documents a personal touch.
                </p>
              </div>
              <div class="feature-box">
                <div className="circule_radioue">
                  <img src={require("../../Image/banner/draw-s.webp")} alt="" />
                </div>
                <h3>Draw Your Signature</h3>
                <p>
                  For those who prefer a more hands-on approach, you can draw
                  your signature directly on the platform using a mouse, stylus,
                  or even your finger on a touchscreen device.
                </p>
              </div>
              <div class="feature-box mr-0">
                <div className="circule_radioue">
                  <img src={require("../../Image/banner/type-s.webp")} alt="" />
                </div>
                <h3>Typed Signature</h3>
                <p>
                  If you’re in a hurry, simply type your name and choose from a
                  variety of font styles to create a professional-looking
                  signature instantly.
                </p>
              </div>
            </div>
          </Container>
        </section>
        <section className="section_defualt  bg-gray_section">
          <Container maxWidth="lg">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="overvide align-items-start"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2>
                      Effortlessly Simplify Your Legal Document Signing Process
                    </h2>

                    <Button
                      onClick={navigate}
                      className="laight_button border_radious_button"
                    >
                      Try for free
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="overvide align-items-start"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <p>
                      Law Blocks AI takes the hassle out of legal document
                      signing. By integrating this feature into our platform, we
                      make it possible for users to manage their entire document
                      signing process in one place. After uploading your
                      document, you can share it with other parties involved,
                      allowing them to sign electronically as well. This
                      eliminates the need for physical paperwork and the delays
                      associated with traditional mailing.
                    </p>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section className="section_defualt sign_processs">
          <Container maxWidth="xl">
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className="d-flex justify-content-center mb-4"
              >
                <div
                  className=" overvide headeing_main"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <h2 className="text-center">Digital Signature Process</h2>
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className="d-flex justify-content-center"
                data-aos="flip-left"
                data-aos-duration="500"
                data-aos-delay="400"
              >
                <div class="upload-steps">
                  <div class="upload-step">
                    <div class="icon">
                      <i class="ri-upload-2-line"></i>
                    </div>
                    <h4>Upload Document</h4>
                  </div>
                  <div class="upload-step">
                    <div class="icon">
                      <i class="ri-hand"></i>
                    </div>
                    <h4>Select Signers</h4>
                  </div>
                  <div class="upload-step">
                    <div class="icon">
                      <i class="ri-sketching"></i>
                    </div>
                    <h4>Digital Signing</h4>
                  </div>
                  <div class="upload-step">
                    <div class="icon">
                      <i class="ri-verified-badge-line"></i>
                    </div>
                    <h4>Verify Identity</h4>
                  </div>
                  <div class="upload-step">
                    <div class="icon">
                      <i class="ri-folder-download-line"></i>
                    </div>
                    <h4>Download and Store</h4>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className="section_defualt  bg-gray_section">
          <Container maxWidth="lg">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={5}>
                  <div
                    className="overvide align-items-start justify-content-start"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2>
                      Security Features of Law Blocks AI Digital Signature
                    </h2>
                    <p>
                      Security is at the core of Law Blocks AI's Digital
                      Signature feature. Key features include
                    </p>
                    <Button
                      onClick={navigate}
                      className="laight_button border_radious_button"
                    >
                      Try for free
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={7}>
                  <div class="keyfetupload-grid item_white">
                    <div class="keyfetupload-item">
                      <h3>Advanced Encryption</h3>
                      <p>
                        Protects documents and signatures from unauthorized
                        access.
                      </p>
                    </div>
                    <div class="keyfetupload-item">
                      <h3>Secure Recording</h3>
                      <p>
                         Ensures the integrity and authenticity of every signed
                        document.
                      </p>
                    </div>
                    <div class="keyfetupload-item">
                      <h3>Audit Trail</h3>
                      <p>
                        Tracks the entire signing process with clear records.
                      </p>
                    </div>
                    <div class="keyfetupload-item">
                      <h3>Blockchain upload</h3>
                      <p>
                         once the documents are signed they can be securely
                        uploaded to blockchain.
                      </p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>

        <section className="section_defualt  why_digilat">
          <Container maxWidth="xl">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="imag_widtjas "
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <img
                      src={require("../../Image/banner/why_sign.webp")}
                      className="img-fluid"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="overvide align-items-start pr-5"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2>
                      Why Choose Law Blocks AI for Your Digital Signing Needs?
                    </h2>
                    <p>
                      Law Blocks AI is not just another digital signature
                      platform; it’s a comprehensive legal solution designed to
                      meet the needs of modern professionals. Here’s why you
                      should choose our platform for your digital signing needs
                    </p>

                    <ul>
                      <li>
                        <b>Convenience:</b> Sign documents from anywhere, at any
                        time, on any device.
                      </li>
                      <li>
                        <b>Versatility:</b> Multiple signing options to suit
                        your preferences.
                      </li>
                      <li>
                        <b>Security:</b> Advanced encryption ensures your
                        documents remain secure.
                      </li>
                      <li>
                        <b>Integration:</b> Seamlessly integrated with Law
                        Blocks AI's suite of legal tools.
                      </li>
                      <li>
                        <b>Efficiency:</b> Save time and streamline your
                        workflow by going digital.
                      </li>
                    </ul>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>

        <section
          className="section_defualt bg_selcton_faq"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={6} className=" mb-4">
                <div className=" overvide headeing_main align-items-start justify-content-start">
                  {/* <span className="lable_badge">FAQ</span> */}
                  <h2 className="text-captailz">Frequently asked questions</h2>
                  <p>
                    Here are some common questions and their answers to help you
                    understand how Law Blocks AI can assist you.
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={6} className=" mb-4">
                <FAQAccordion faqs={faqs} />
              </Grid>
            </Grid>
          </Container>
        </section>
      </main>
      <div></div>
      <Footer />
    </div>
  );
}

export default Home;

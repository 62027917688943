import React from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
} from "@mui/material";

const BlogCard = ({title, description, imagePath, category, date, slug}) => {
  return (
    <a href={`/blogdetails/${slug}`} target="_blank" className="card_link">
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
          height: "100%",
          padding: "16px 16px",
        }}
        className="card_dark"
      >
        <CardContent
          sx={{
            padding: "0",
          }}
        >
          <div className="card_inside_content">
            <div className="blog-image h-100">
              <img src={imagePath} className="img-fluid" alt={title} />
            </div>
            <h3>{title}</h3>
            <div className="blog_data">
              <span>
                <i className="ri-article-line"></i>
                {category}
              </span>
              <span>
                <i className="ri-calendar-line"></i>
                {date}
              </span>
            </div>
            <p className="text_card">{description}</p>
          </div>
        </CardContent>
      </Card>
    </a>
  );
};

export default BlogCard;

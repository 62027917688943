import React, {useState, useEffect} from "react";
import Header from "./componant/HeaderNew";
import {Button} from "@mui/material";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Footer from "./Footer";
import BlogCard from "./componant/BlogCard";
// import Blogtabs from "./componant/Blogtabs";
function Home() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Show 3 slides on desktop
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024, // Breakpoint for desktop
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768, // Breakpoint for tablet
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480, // Breakpoint for mobile
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const [isScrolled, setIsScrolled] = useState(false);
  // useEffect(() => {
  //   function handleScroll() {
  //     const fourthSection = document.querySelector("#section-4");
  //     const fourthSectionTop = fourthSection.offsetTop;
  //     const currentScrollPosition = window.pageYOffset + window.innerHeight;
  //     if (currentScrollPosition >= fourthSectionTop) {
  //       setIsScrolledToFourthSection(true);
  //     } else {
  //       setIsScrolledToFourthSection(false);
  //     }
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  useEffect(() => {
    function handleScroll() {
      const fourthSection = document.querySelector("#section-4");
      const header = document.querySelector("header");
      const {top} = fourthSection.getBoundingClientRect();
      const headerHeight = header.offsetHeight;
      setIsScrolled(top <= headerHeight);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navigate = () => {
    window.open("https://market.lawblocks.io/login", "_blank");
  };
  const posts = [
    {
      slug:
        "marketplace-for-lawyers-a-new-era-of-legal-service-delivery-law-blocks-ai",
      title:
        "Marketplace for Lawyers: A New Era of Legal Service Delivery - Law Blocks AI",
      category: "Marketplace for Lawyers",
      date: "July 12, 2024",
      description:
        "Explore how Law Blocks AI is transforming legal service delivery with a new marketplace for lawyers. Discover the benefits of AI-powered legal solutions, ",

      likes: 220,
      imagePath:
        "https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/Marketplace_slfuz1.jpg",
    },
    {
      slug: "benefits-of-ai-chat-for-legal-professionals-and-clients",
      title: "The Benefits of AI Chat for Legal Professionals and Clients",
      category: "Global Information AI",
      date: "July 12, 2024",
      description:
        "Discover the advantages of AI chat for legal professionals and clients with Law Blocks AI. Learn how AI-powered chat enhances communication,",

      likes: 430,
      imagePath:
        "https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/ai_chat_sisdav.jpg",
    },
    {
      slug: "how-digital-signature-features-work-on-law-blocks-ai",
      title: "How Digital Signature Features Work on Law Blocks AI",
      category: "Digital Signature",
      date: "July 12, 2024",
      description:
        "Learn how Law Blocks AI enhances digital signature features to provide secure, efficient, and legally binding document signing. ",

      likes: 650,
      imagePath:
        "https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/ds_thnxrv.jpg",
    },
    {
      slug: "ai-legal-document-creation-with-law-blocks-ai",
      title: "AI Legal Document Creation with Law Blocks AI",
      category: "AI Legal Document",
      description:
        "Explore how Law Blocks AI revolutionizes legal document creation with advanced AI technology. Discover efficient, accurate, and secure ",

      date: "July 12, 2024",
      likes: 120,
      imagePath:
        "https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/AIdoc_mtapec.jpg",
    },

    {
      slug: "how-secure-are-the-documents-stored-on-law-blocks-ai",
      title: "How Secure Are the Documents Stored on Law Blocks AI?",
      category: "Uploading on blockchain",
      date: "July 12, 2024",
      description:
        "Explore how Law Blocks AI uses blockchain technology to ensure the security, integrity, and transparency of stored legal documents.",
      likes: 150,
      imagePath:
        "https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/LAw_Blocks_AI_Docu_cnklbl.jpg",
    },
    {
      slug:
        "simplifying-adr-through-arbitration-and-mediation-with-law-blocks-ai",
      title:
        "Simplifying ADR through Arbitration and Mediation with Law Blocks AI",
      category: "Mediation and Arbitration",
      description:
        "Discover how Law Blocks AI simplifies Alternative Dispute Resolution (ADR) through advanced AI-powered arbitration and mediation solutions.",
      date: "July 12, 2024",
      likes: 180,
      imagePath:
        "https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/arb_and_mediation_iz4boo.jpg",
    },
  ];

  return (
    <div className={isScrolled ? "header scrolled" : "header blog_body"}>
      <Header />
      <main>
        <section className="section_banner_hero blog_banner" id="section-4">
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={6}>
                <div className="hero_seaction_law blog_home_banner align-items-start">
                  <h1 data-aos="zoom-in" data-aos-duration="500">
                    Tech is changing. Stay informed.
                  </h1>

                  <p data-aos="zoom-in" data-aos-duration="500">
                    Discover articles and news on everything that's happening in
                    Law Blocks AI.
                  </p>
                  <Button
                    className="laight_button mt-3"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                  >
                    Contact us
                  </Button>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={6}
                className="d-flex justify-content-center align-items-center"
              >
                <div className="">
                  <img
                    src={require("../Image/banner/blog_home_banner.png")}
                    className="img-fluid image_blog"
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className="section_defualt  baner_blog_top">
          <Container maxWidth="lg">
            <Slider {...settings}>
              {posts.map((post, index) => (
                <div className="slider_card">
                  <BlogCard
                    key={index}
                    title={post.title}
                    description={post.description}
                    imagePath={post.imagePath}
                    category={post.category}
                    date={post.date}
                    slug={post.slug}
                  />
                </div>
              ))}
            </Slider>
          </Container>
        </section>
        <section className="section_defualt ">
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12} className=" mb-4">
                <div
                  className=" overvide headeing_main align-items-center articelee related"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  {/* <span className="lable_badge">Our Blog</span> */}
                  <h2 className="text-captailz text-center">Latest articles</h2>
                  <a href="/blog" className="onliy_for_link">
                    <Button className="laight_button light_cwnte">
                      See All Articles
                    </Button>
                  </a>
                </div>
              </Grid>
              {posts.map((post, index) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={4}
                  className="d-flex justify-content-center"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <BlogCard
                    key={index}
                    title={post.title}
                    description={post.description}
                    imagePath={post.imagePath}
                    category={post.category}
                    date={post.date}
                    slug={post.slug}
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
        </section>
        <section className="blog_tabs">
          <div class="container mt-5">
            <div className="overvide headeing_main d-flex align-item-center justify-content-center mb-4  flex-direction-row ">
              <h2 className="text-center"> Category</h2>
            </div>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a
                  class="nav-link active"
                  id="ai-legal-documents-tab"
                  data-toggle="tab"
                  href="#ai-legal-documents"
                  role="tab"
                  aria-controls="ai-legal-documents"
                  aria-selected="true"
                >
                  AI Legal Documents
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="smart-legal-contracts-tab"
                  data-toggle="tab"
                  href="#smart-legal-contracts"
                  role="tab"
                  aria-controls="smart-legal-contracts"
                  aria-selected="false"
                >
                  Smart Legal Contracts
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="digital-signature-tab"
                  data-toggle="tab"
                  href="#digital-signature"
                  role="tab"
                  aria-controls="digital-signature"
                  aria-selected="false"
                >
                  Digital Signature
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="uploading-blockchain-tab"
                  data-toggle="tab"
                  href="#uploading-blockchain"
                  role="tab"
                  aria-controls="uploading-blockchain"
                  aria-selected="false"
                >
                  Uploading Blockchain
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="global-information-ai-tab"
                  data-toggle="tab"
                  href="#global-information-ai"
                  role="tab"
                  aria-controls="global-information-ai"
                  aria-selected="false"
                >
                  Global Information AI
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="mediation-tab"
                  data-toggle="tab"
                  href="#mediation"
                  role="tab"
                  aria-controls="mediation"
                  aria-selected="false"
                >
                  Mediation
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  id="arbitration-tab"
                  data-toggle="tab"
                  href="#arbitration"
                  role="tab"
                  aria-controls="arbitration"
                  aria-selected="false"
                >
                  Arbitration
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="ai-legal-documents"
                role="tabpanel"
                aria-labelledby="ai-legal-documents-tab"
              >
                <a
                  href="/blogdetails/ai-legal-document-creation-with-law-blocks-ai"
                  tabIndex="_blank"
                  className="card_link"
                >
                  <div class="card card-custom">
                    <div class="card-content">
                      <div class="card-inside-content">
                        <div className="row">
                          <div className="col-lg-4 d-flex align-items-center">
                            <div class="blog-image">
                              <img
                                src="https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/AIdoc_mtapec.jpg"
                                class="img-fluid"
                                alt="Blog Image"
                              />
                            </div>
                          </div>
                          <div className="col-lg-8 d-flex align-items-center">
                            <div class="content">
                              <div class="blog_data">
                                <span>AI Legal Document</span>
                                <span>
                                  <i class="ri-eye-line"></i> 100
                                </span>
                              </div>
                              <h3>
                                AI Legal Document Creation with Law Blocks AI
                              </h3>
                              <p>
                                Explore how Law Blocks AI revolutionizes legal
                                document creation with advanced AI technology.
                                Discover efficient, accurate, and secure
                                solutions for drafting legal documents,
                                contracts, and agreements.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div
                class="tab-pane fade"
                id="smart-legal-contracts"
                role="tabpanel"
                aria-labelledby="smart-legal-contracts-tab"
              >
                <a
                  href="/blogdetails/how-secure-are-the-documents-stored-on-law-blocks-ai"
                  tabIndex="_blank"
                  className="card_link"
                >
                  <div class="card card-custom">
                    <div class="card-content">
                      <div class="card-inside-content">
                        <div className="row">
                          <div className="col-lg-4 d-flex align-items-center">
                            <div class="blog-image">
                              <img
                                src="https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/LAw_Blocks_AI_Docu_cnklbl.jpg"
                                class="img-fluid"
                                alt="Blog Image"
                              />
                            </div>
                          </div>
                          <div className="col-lg-8 d-flex align-items-center">
                            <div class="content">
                              <div class="blog_data">
                                <span>Smart Legal Contracts</span>
                                <span>
                                  <i class="ri-eye-line"></i> 100
                                </span>
                              </div>
                              <h3>
                                How Secure Are the Documents Stored on Law
                                Blocks AI?
                              </h3>
                              <p>
                                Explore how Law Blocks AI uses blockchain
                                technology to ensure the security, integrity,
                                and transparency of stored legal documents.
                                Learn about advanced document security features
                                and how they protect sensitive information.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div
                class="tab-pane fade"
                id="digital-signature"
                role="tabpanel"
                aria-labelledby="digital-signature-tab"
              >
                <a
                  href="/blogdetails/how-digital-signature-features-work-on-law-blocks-ai"
                  tabIndex="_blank"
                  className="card_link"
                >
                  <div class="card card-custom">
                    <div class="card-content">
                      <div class="card-inside-content">
                        <div className="row">
                          <div className="col-lg-4 d-flex align-items-center">
                            <div class="blog-image">
                              <img
                                src="https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/ds_thnxrv.jpg"
                                class="img-fluid"
                                alt="Blog Image"
                              />
                            </div>
                          </div>
                          <div className="col-lg-8 d-flex align-items-center">
                            <div class="content">
                              <div class="blog_data">
                                <span>Digital Signature</span>
                                <span>
                                  <i class="ri-eye-line"></i> 100
                                </span>
                              </div>
                              <h3>
                                How Digital Signature Features Work on Law
                                Blocks AI
                              </h3>
                              <p>
                                Learn how Law Blocks AI enhances digital
                                signature features to provide secure, efficient,
                                and legally binding document signing. Explore
                                the benefits of digital signatures in
                                streamlining legal processes and ensuring
                                document integrity.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div
                class="tab-pane fade"
                id="uploading-blockchain"
                role="tabpanel"
                aria-labelledby="uploading-blockchain-tab"
              >
                <a
                  href="/blogdetails/how-secure-are-the-documents-stored-on-law-blocks-ai"
                  tabIndex="_blank"
                  className="card_link"
                >
                  <div class="card card-custom">
                    <div class="card-content">
                      <div class="card-inside-content">
                        <div className="row">
                          <div className="col-lg-4 d-flex align-items-center">
                            <div class="blog-image">
                              <img
                                src="https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/LAw_Blocks_AI_Docu_cnklbl.jpg"
                                class="img-fluid"
                                alt="Blog Image"
                              />
                            </div>
                          </div>
                          <div className="col-lg-8 d-flex align-items-center">
                            <div class="content">
                              <div class="blog_data">
                                <span>Uploading on blockchain</span>
                                <span>
                                  <i class="ri-eye-line"></i> 100
                                </span>
                              </div>
                              <h3>
                                How Secure Are the Documents Stored on Law
                                Blocks AI?
                              </h3>
                              <p>
                                Explore how Law Blocks AI uses blockchain
                                technology to ensure the security, integrity,
                                and transparency of stored legal documents.
                                Learn about advanced document security features
                                and how they protect sensitive information.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div
                class="tab-pane fade"
                id="global-information-ai"
                role="tabpanel"
                aria-labelledby="global-information-ai-tab"
              >
                <a
                  href="/blogdetails/benefits-of-ai-chat-for-legal-professionals-and-clients"
                  tabIndex="_blank"
                  className="card_link"
                >
                  <div class="card card-custom">
                    <div class="card-content">
                      <div class="card-inside-content">
                        <div className="row">
                          <div className="col-lg-4 d-flex align-items-center">
                            <div class="blog-image">
                              <img
                                src="https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/ai_chat_sisdav.jpg"
                                class="img-fluid"
                                alt="Blog Image"
                              />
                            </div>
                          </div>
                          <div className="col-lg-8 d-flex align-items-center">
                            <div class="content">
                              <div class="blog_data">
                                <span>Global Information AI</span>
                                <span>
                                  <i class="ri-eye-line"></i> 100
                                </span>
                              </div>
                              <h3>
                                The Benefits of AI Chat for Legal Professionals
                                and Clients
                              </h3>
                              <p>
                                Discover the advantages of AI chat for legal
                                professionals and clients with Law Blocks AI.
                                Learn how AI-powered chat enhances
                                communication, efficiency, and client
                                satisfaction in the legal industry.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div
                class="tab-pane fade"
                id="mediation"
                role="tabpanel"
                aria-labelledby="mediation-tab"
              >
                <a
                  href="/blogdetails/simplifying-adr-through-arbitration-and-mediation-with-law-blocks-ai"
                  tabIndex="_blank"
                  className="card_link"
                >
                  <div class="card card-custom">
                    <div class="card-content">
                      <div class="card-inside-content">
                        <div className="row">
                          <div className="col-lg-4 d-flex align-items-center">
                            <div class="blog-image">
                              <img
                                src="https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/arb_and_mediation_iz4boo.jpg"
                                class="img-fluid"
                                alt="Blog Image"
                              />
                            </div>
                          </div>
                          <div className="col-lg-8 d-flex align-items-center">
                            <div class="content">
                              <div class="blog_data">
                                <span>Mediation and Arbitration</span>
                                <span>
                                  <i class="ri-eye-line"></i> 100
                                </span>
                              </div>
                              <h3>
                                Simplifying ADR through Arbitration and
                                Mediation with Law Blocks AI
                              </h3>
                              <p>
                                Discover how Law Blocks AI simplifies
                                Alternative Dispute Resolution (ADR) through
                                advanced AI-powered arbitration and mediation
                                solutions. Learn about efficient, secure, and
                                effective legal technology for resolving
                                disputes.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              <div
                class="tab-pane fade"
                id="arbitration"
                role="tabpanel"
                aria-labelledby="arbitration-tab"
              >
                <div class="card card-custom">
                  <div class="card-content">
                    <div class="card-inside-content">
                      <div className="row">
                        <div className="col-lg-4 d-flex align-items-center">
                          <div class="blog-image">
                            <img
                              src={require("../Image/banner/blog-imag.png")}
                              class="img-fluid"
                              alt="Blog Image"
                            />
                          </div>
                        </div>
                        <div className="col-lg-8 d-flex align-items-center">
                          <div class="content">
                            <div class="blog_data">
                              <span>Content Creation</span>
                              <span>
                                <i class="ri-eye-line"></i> 100
                              </span>
                            </div>
                            <h3>
                              Why Slack is Problematic and Ruining Deep Work for
                              Staff
                            </h3>
                            <p>
                              Dicta nihil ratione corrupti. Aut dolorem dolores
                              omnis laboriosam ratione sequi. Provident ad sed
                              velit. Est ea ab.Dicta nihil ratione corrupti. Aut
                              dolorem dolores omnis laboriosam ratione sequi.
                              Provident ad sed velit. Est ea ab.Dicta nihil
                              ratione corrupti. Aut dolorem dolores omnis
                              laboriosam ratione sequi. Provident ad sed velit.
                              Est ea ab.Dicta nihil ratione corrupti. Aut
                              dolorem dolores omnis laboriosam ratione sequi.
                              Provident ad sed velit. Est ea ab.Dicta nihil
                              ratione corrupti. Aut dolorem dolores omnis
                              laboriosam ratione sequi. Provident ad sed velit.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card card-custom">
                  <div class="card-content">
                    <div class="card-inside-content">
                      <div className="row">
                        <div className="col-lg-4 d-flex align-items-center">
                          <div class="blog-image">
                            <img
                              src={require("../Image/banner/blog-imag.png")}
                              class="img-fluid"
                              alt="Blog Image"
                            />
                          </div>
                        </div>
                        <div className="col-lg-8 d-flex align-items-center">
                          <div class="content">
                            <div class="blog_data">
                              <span>Content Creation</span>
                              <span>
                                <i class="ri-eye-line"></i> 100
                              </span>
                            </div>
                            <h3>
                              Why Slack is Problematic and Ruining Deep Work for
                              Staff
                            </h3>
                            <p>
                              Dicta nihil ratione corrupti. Aut dolorem dolores
                              omnis laboriosam ratione sequi. Provident ad sed
                              velit. Est ea ab.Dicta nihil ratione corrupti. Aut
                              dolorem dolores omnis laboriosam ratione sequi.
                              Provident ad sed velit. Est ea ab.Dicta nihil
                              ratione corrupti. Aut dolorem dolores omnis
                              laboriosam ratione sequi. Provident ad sed velit.
                              Est ea ab.Dicta nihil ratione corrupti. Aut
                              dolorem dolores omnis laboriosam ratione sequi.
                              Provident ad sed velit. Est ea ab.Dicta nihil
                              ratione corrupti. Aut dolorem dolores omnis
                              laboriosam ratione sequi. Provident ad sed velit.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="overvide headeing_main d-flex align-item-center justify-content-center mt-4 flex-direction-row ">
                <a href="/blog" className="card_link">
                  <Button className="laight_button light_cwnte">
                    See All Articles
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section className="section_defualt bg-gray_section mt-5">
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12} className="">
                <div
                  className=" overvide headeing_main align-items-center"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  {/* <span className="lable_badge">Our Blog</span> */}
                  <h2 className="text-captailz text-center mb-2">
                    Leading the Way in AI Legal Technology
                  </h2>
                  <p>Get the latest product and management insights.</p>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={12} className=" mb-4">
                <div
                  className="input_fore"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <input type="email" placeholder="Enter your email" />
                  <button>Subscribe</button>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
      </main>
      <Footer />
    </div>
  );
}

export default Home;

import React, {useState, useEffect} from "react";
import Header from "../componant/HeaderNew";
import {Button} from "@mui/material";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Footer from "../Footer";
import {Helmet} from "react-helmet";
// import Bgvedio from "../Image/globe-fiat-block.mp4";

import FAQAccordion from "../componant/FAQAccordionProps";
import {Link} from "react-router-dom";

function Home() {
  const [isScrolled, setIsScrolled] = useState(false);
  // useEffect(() => {
  //   function handleScroll() {
  //     const fourthSection = document.querySelector("#section-4");
  //     const fourthSectionTop = fourthSection.offsetTop;
  //     const currentScrollPosition = window.pageYOffset + window.innerHeight;
  //     if (currentScrollPosition >= fourthSectionTop) {
  //       setIsScrolledToFourthSection(true);
  //     } else {
  //       setIsScrolledToFourthSection(false);
  //     }
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  useEffect(() => {
    function handleScroll() {
      const fourthSection = document.querySelector("#section-4");
      const header = document.querySelector("header");
      const {top} = fourthSection.getBoundingClientRect();
      const headerHeight = header.offsetHeight;
      setIsScrolled(top <= headerHeight);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const faqs = [
    {
      question: "What is Law Blocks AI's Marketplace for Lawyers?",
      answer:
        "It's an online platform where legal professionals can offer their services, find clients, and access advanced legal tools.",
    },
    {
      question: "How does the Digital Signature feature work?",
      answer:
        "You can sign documents digitally using three different methods: uploading a signature, drawing one, or typing your name and selecting a font style.",
    },
    {
      question: "Can I contribute legal documents to the platform?",
      answer:
        "Yes, attorneys can create and upload their legal documents to the marketplace and earn LBT tokens with each purchase.",
    },
    {
      question: "Is Law Blocks AI secure?",
      answer:
        "Absolutely. We use advanced encryption and blockchain technology to ensure the security and integrity of your documents and transactions.",
    },
  ];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024, // For large tablets and smaller laptops
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // For tablets
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // For mobile phones
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const navigate = () => {
    window.open("https://market.lawblocks.io/login", "_blank");
  };

  return (
    <div className={isScrolled ? "header scrolled" : "header color_white"}>
      <Header />
      <Helmet>
        <title>
          LBT Supported Crypto Wallets and Exchanges - Trade and Manage Your LBT
          Tokens Securely
        </title>
        <meta
          property="og:title"
          content="LBT Supported Crypto Wallets and Exchanges - Trade and Manage Your LBT Tokens Securely"
        />
        <meta
          property="og:description"
          content="Discover how Law Blocks AI integrates LBT tokens with top crypto exchanges and secure wallets. Trade, store, and manage your LBT tokens with leading platforms like Bitmart, Bitrue, Coingecko, CoinMarketCap, Xswap, and more. Ensure convenience and security for your digital assets with our trusted partners."
        />
        {/* <!-- Uncomment and replace with actual image URL if available -->
<!-- <meta
  property="og:image"
  content="https://lawblocks.io/assets/lbt-crypto-wallets-exchanges-thumbnail.jpg"
/> --> */}
        <meta
          property="og:url"
          content="https://lawblocks.io/lbt-supported-wallets-exchanges"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="LBT Supported Crypto Wallets and Exchanges - Trade and Manage Your LBT Tokens Securely"
        />
        <meta
          name="twitter:description"
          content="Law Blocks AI offers seamless integration of LBT tokens with top crypto exchanges and secure wallets. Explore leading platforms like Bitmart, Bitrue, Coingecko, CoinMarketCap, and Xswap for efficient and secure trading and storage of your LBT tokens."
        />
        {/* <!-- Uncomment and replace with actual image URL if available -->
<!-- <meta
  name="twitter:image"
  content="https://lawblocks.io/assets/lbt-crypto-wallets-exchanges-thumbnail.jpg"
/> --> */}
      </Helmet>

      <main>
        {/* <SectionWithVideoBackground /> */}
        <section
          className="section_banner_hero new_Section_AIdoc  bg-gray_section-hero  section_fixed_height_sedcion bg_imge_non"
          id="section-4"
        >
          {/* <div className="purble"></div>
          <div className="yellow"></div>
          <div className="green"></div> */}

          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                md={12}
                lg={6}
                className="d-flex align-item-center justiy-content-center"
              >
                <div className="hero_seaction_law align-items-start justify-content-center fixed_hero_height">
                  <h1
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-left mb-2"
                  >
                    LBT Supported Crypto Wallets and Exchanges
                  </h1>

                  <p
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-left m-0"
                  >
                    Trade, store and manage your LBT token with the leading
                    crypto wallets and exchange platforms.
                  </p>
                  <Button
                    className="laight_button border_radious_button"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    onClick={navigate}
                  >
                    Get Started
                  </Button>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={6}
                className="d-flex align-items-center justify-content-center"
              >
                <div
                  className="imag_widtjas image_contain_digital d-flex justify-content-center align-items-center"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <img
                    src={require("../../Image/banner/banner_wallet.png")}
                    className="img-fluid img_center_section_wallet"
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>

        <section className="section_defualt  ">
          <Container maxWidth="lg">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <div
                    className="imag_widtjas image_contain_digital d-flex justify-content-center align-items-center overvide"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2 className="mb-4 text-center">
                      Introduction to Law Blocks AI's Crypto Integration
                    </h2>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="imag_widtjas image_contain_digital d-flex justify-content-center align-items-center overvide"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <img
                      src={require("../../Image/banner/AiCardwallet.png")}
                      className="img-fluid w-80"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="overvide align-items-start justify-content-end"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <p className="mb-4">
                      Law Blocks AI revolutionizes the legal tech landscape by
                      combining blockchain technology with legal document
                      automation. A vital component of this innovation is the
                      integration of LBT tokens into top crypto exchanges and
                      secure crypto wallets, allowing seamless trading and
                      secure storage of your digital assets. The LBT token is
                      the backbone of Law Blocks AI, facilitating transactions,
                      legal document purchases, and rewarding contributors on
                      the platform.
                    </p>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>

        <section className="section_defualt  bg-gray_section pt-4">
          <Container maxWidth="lg">
            <div className="padding_adjust">
              <Grid container spacing={3} className="justify-content-center">
                <Grid item xs={12} md={12} lg={9}>
                  <div
                    className="overvide align-items-center justify-content-center mb-5"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2 className="text-center">
                      Top Crypto Exchanges Listing for <br />
                      LBT Token
                    </h2>
                    <p className="text-center">
                      Law Blocks AI has strategically partnered with some of the
                      most respected exchanges in the crypto space, making LBT
                      token trading accessible globally. Each exchange offers
                      unique features tailored to crypto enthusiasts, traders,
                      and investors.
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12} className="logo_slide">
                  {/* <div class="keyfetupload-grid item_white logo_barnd">
                    <div class="keyfetupload-item">
                      <a href="" className="d-block">
                        <div className="img_contaoiner">
                          <img
                            src={require("../../Image/banner/bitmart.png")}
                            className="img-fluid"
                          />
                        </div>
                      </a>
                    </div>
                    <div class="keyfetupload-item">
                      <a href="" className="d-block">
                        <div className="img_contaoiner">
                          <img
                            src={require("../../Image/banner/bitture.png")}
                            className="img-fluid"
                          />
                        </div>
                      </a>
                    </div>
                    <div class="keyfetupload-item">
                      <a href="" className="d-block">
                        {" "}
                        <div className="img_contaoiner">
                          <img
                            src={require("../../Image/banner/coingecko.png")}
                            className="img-fluid filter_non"
                          />
                        </div>
                      </a>
                    </div>
                    <div class="keyfetupload-item">
                      <a href="" className="d-block">
                        <div className="img_contaoiner">
                          <img
                            src={require("../../Image/banner/coinmarketcap.png")}
                            className="img-fluid"
                          />
                        </div>
                      </a>
                    </div>
                  </div> */}
                  <Slider {...settings}>
                    <div class="keyfetupload-grid item_white logo_barnd w-100">
                      <div class="keyfetupload-item max-w-100">
                        <a href="" className="d-block">
                          <div className="img_contaoiner">
                            <img
                              src={require("../../Image/banner/bitmart.png")}
                              className="img-fluid"
                            />
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="keyfetupload-grid item_white logo_barnd w-100">
                      <div class="keyfetupload-item max-w-100">
                        <a href="" className="d-block">
                          <div className="img_contaoiner">
                            <img
                              src={require("../../Image/banner/bitture.png")}
                              className="img-fluid"
                            />
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="keyfetupload-grid item_white logo_barnd w-100">
                      <div class="keyfetupload-item max-w-100">
                        <a href="" className="d-block">
                          {" "}
                          <div className="img_contaoiner">
                            <img
                              src={require("../../Image/banner/coingecko.png")}
                              className="img-fluid filter_non"
                            />
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="keyfetupload-grid item_white logo_barnd w-100">
                      <div class="keyfetupload-item max-w-100">
                        <a href="" className="d-block">
                          <div className="img_contaoiner">
                            <img
                              src={require("../../Image/banner/coinmarketcap.png")}
                              className="img-fluid"
                            />
                          </div>
                        </a>
                      </div>
                    </div>
                    <div class="keyfetupload-grid item_white logo_barnd w-100">
                      <div class="keyfetupload-item max-w-100">
                        <a href="" className="d-block">
                          {" "}
                          <div className="img_contaoiner">
                            <img
                              src={require("../../Image/banner/coingecko.png")}
                              className="img-fluid filter_non"
                            />
                          </div>
                        </a>
                      </div>
                    </div>
                  </Slider>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section
          className="section_defualt "
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          <Container maxWidth="xl">
            <Grid container spacing={3} className="justify-content-center">
              <Grid item xs={12} md={12} lg={12}>
                <Grid container spacing={3} className="justify-content-center">
                  <Grid item xs={12} md={12} lg={7}>
                    <div
                      className="overvide align-items-center"
                      data-aos="zoom-in"
                      data-aos-duration="500"
                      data-aos-delay="200"
                    >
                      <h2 className="text-center">
                        LBT Token Supported Wallets
                      </h2>
                      <p className="text-center">
                        When it comes to storing LBT tokens, security is
                        paramount. Law Blocks AI supports a range of reputable
                        crypto wallets that offer different levels of security
                        and functionality, from biometric protection to cold
                        storage solutions.
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <div>
                  <Slider {...settings}>
                    <div class="feature-box ml-0">
                      <div className="circule_radioue">
                        <img
                          src={require("../../Image/banner/dcentwallet.png")}
                          alt=""
                          className="filter_non"
                        />
                      </div>
                      <h3>D'Cent Wallet</h3>
                      <p>
                        D'Cent uses biometric fingerprint security for LBT
                        tokens, ensuring a safe, user-friendly experience across
                        multiple blockchains.
                      </p>
                    </div>
                    <div class="feature-box ml-0">
                      <div className="circule_radioue">
                        <img
                          src={require("../../Image/banner/garuda.png")}
                          alt=""
                          className="filter_non"
                        />
                      </div>
                      <h3>Guarda Wallet</h3>
                      <p>
                        Guarda is a non-custodial, multi-platform wallet that
                        offers secure storage, management, and exchange of LBT
                        tokens across devices.
                      </p>
                    </div>
                    <div class="feature-box ml-0">
                      <div className="circule_radioue">
                        <img
                          src={require("../../Image/banner/ellipal.png")}
                          alt=""
                          className="filter_non"
                        />
                      </div>
                      <h3>Ellipal Wallet</h3>
                      <p>
                        Trezor offers top-tier hardware wallet security for LBT
                        tokens, using strong encryption and intuitive design for
                        optimal protection.
                      </p>
                    </div>
                    <div class="feature-box ml-0">
                      <div className="circule_radioue">
                        <img
                          src={require("../../Image/banner/trezor.png")}
                          alt=""
                          className=""
                        />
                      </div>
                      <h3>Trezor Wallet</h3>
                      <p>
                        Trezor offers top-tier hardware wallet security for LBT
                        tokens, using strong encryption and intuitive design for
                        optimal protection.
                      </p>
                    </div>
                    <div class="feature-box ml-0">
                      <div className="circule_radioue">
                        <img
                          src={require("../../Image/banner/dcentwallet.png")}
                          alt=""
                          className="filter_non"
                        />
                      </div>
                      <h3>D'Cent Wallet</h3>
                      <p>
                        D'Cent uses biometric fingerprint security for LBT
                        tokens, ensuring a safe, user-friendly experience across
                        multiple blockchains.
                      </p>
                    </div>
                    <div class="feature-box ml-0">
                      <div className="circule_radioue">
                        <img
                          src={require("../../Image/banner/ellipal.png")}
                          alt=""
                          className="filter_non"
                        />
                      </div>
                      <h3>Ellipal Wallet</h3>
                      <p>
                        Trezor offers top-tier hardware wallet security for LBT
                        tokens, using strong encryption and intuitive design for
                        optimal protection.
                      </p>
                    </div>
                  </Slider>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
      </main>
      <div></div>
      <Footer />
    </div>
  );
}

export default Home;

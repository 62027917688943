import React from "react";
import Bgvedio from "../../Image/banner/Flowing.mp4";
import {Button} from "@mui/material";

const SectionWithVideoBackground = () => {
  const navigate = () => {
    window.open("https://market.lawblocks.io/login", '_blank');
  }
  return (
    <section className="video-background">
      <video autoPlay muted loop>
        <source src={Bgvedio} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="section-content">
        <div className="hero_seaction_law ">
          <h1 data-aos="zoom-in" data-aos-duration="500">
            Law Blocks AI
          </h1>
          <h2 data-aos="zoom-in" data-aos-duration="500">
            {" "}
            Legal Tech Ecosystem 
          </h2>
          <p data-aos="zoom-in" data-aos-duration="500">
            Upgrade your legal services with Law Blocks AI, integrating
            innovative AI in law and blockchain technology.
            <br /> Solve all your legal issues with our advanced AI legal
            assistant, designed to streamline your practice.
          </p>
          <Button
            className="laight_button"
            data-aos="zoom-in"
            onClick={navigate}
            data-aos-duration="500"
          >
            Signup Now
          </Button>
        </div>
      </div>
    </section>
  );
};

export default SectionWithVideoBackground;

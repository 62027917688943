import React, {useState} from "react";
import {Grid, Modal, Box, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import zIndex from "@mui/material/styles/zIndex";
import {Padding} from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  backgroundColor: "transparent !important",
  //   border: "2px solid #fff",
  boxShadow: 24,
  Padding: "22px",
  zIndex: "555555",
  borderRadius: "10px",
};

const ImageWithModal = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Grid item xs={12} md={12} lg={12}>
        <div className="img_section" onClick={handleOpen}>
          <img
            src={require("../../Image/banner/MacBook_Air.webp")}
            className="img-fluid"
            alt="MacBook Air"
          />
        </div>
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className="modle_popup"
      >
        <Box sx={style} className="button_moduke">
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <div className="video-wrapper">
            <iframe
              width="800"
              height="400"
              src="https://www.youtube.com/embed/jEH36bvNcLQ"
              title="Key Features of Law Blocks AI | AI in Law | Blockchain Secure Document Storage System"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ImageWithModal;

import React, {useState} from "react";
import {
  Container,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import {Add} from "@mui/icons-material";

const FAQAccordion = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            style={{
              marginBottom: "16px",
              backgroundColor: "#f9f9f9",
              boxShadow: "none",
              minHeight: "70px",
              borderRadius: "5px",
              overflow: "hidden",
            }}
            className="card_dark darkPrice Faq_card"
          >
            <AccordionSummary
              expandIcon={
                <Add
                  style={{
                    transform:
                      expanded === "panel1" ? "rotate(90deg)" : "rotate(0deg)",
                  }}
                />
              }
            >
              <Typography className="text_faq">
                What legal documents can I create with Law Blocks AI?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                With Law Blocks AI, you can create various legal documents,
                including business contracts, lease agreements, non-disclosure
                agreements, wills, and power of attorney documents. Our platform
                ensures that each document adheres to the latest legal standards
                and practices.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            style={{
              marginBottom: "16px",
              backgroundColor: "#f9f9f9",
              boxShadow: "none",
              minHeight: "70px",
              borderRadius: "5px",
              overflow: "hidden",
            }}
            className="card_dark darkPrice Faq_card"
          >
            <AccordionSummary
              expandIcon={
                <Add
                  style={{
                    transform:
                      expanded === "panel2" ? "rotate(90deg)" : "rotate(0deg)",
                  }}
                />
              }
            >
              <Typography className="text_faq">
                Who can use Law Blocks AI?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Law Blocks AI is designed for a range of users, including legal
                professionals such as lawyers, Advocates, law firms, corporate
                legal departments, and common people who want to securely store
                their documents on blockchain and other document-related needs.
                The platform is also an invaluable tool for businesses of all
                sizes that require regular legal document management and smart
                contract execution.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            style={{
              marginBottom: "16px",
              backgroundColor: "#f9f9f9",
              boxShadow: "none",
              minHeight: "70px",
              borderRadius: "5px",
              overflow: "hidden",
            }}
            className="card_dark darkPrice Faq_card"
          >
            <AccordionSummary
              expandIcon={
                <Add
                  style={{
                    transform:
                      expanded === "panel3" ? "rotate(90deg)" : "rotate(0deg)",
                  }}
                />
              }
            >
              <Typography className="text_faq">
                Do you offer a free trial?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Yes, we offer a free trial for Law Blocks AI so you can explore
                the platform and experience its features firsthand.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            style={{
              marginBottom: "16px",
              backgroundColor: "#f9f9f9",
              boxShadow: "none",
              minHeight: "70px",
              borderRadius: "5px",
              overflow: "hidden",
            }}
            className="card_dark darkPrice Faq_card"
          >
            <AccordionSummary
              expandIcon={
                <Add
                  style={{
                    transform:
                      expanded === "panel4" ? "rotate(90deg)" : "rotate(0deg)",
                  }}
                />
              }
            >
              <Typography className="text_faq">
                How do I get started with Law Blocks AI?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Getting started is easy! Sign up for a free trial on our
                website, and you'll be guided through the platform's
                functionalities. We also offer comprehensive support resources
                and tutorials.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
            style={{
              marginBottom: "16px",
              backgroundColor: "#f9f9f9",
              boxShadow: "none",
              minHeight: "70px",
              borderRadius: "5px",
              overflow: "hidden",
            }}
            className="card_dark darkPrice Faq_card"
          >
            <AccordionSummary
              expandIcon={
                <Add
                  style={{
                    transform:
                      expanded === "panel5" ? "rotate(90deg)" : "rotate(0deg)",
                  }}
                />
              }
            >
              <Typography className="text_faq">
                How does blockchain technology work within Law Blocks AI?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                The blockchain provides a secure and transparent ledger for
                storing your legal documents. This ensures that all parties
                involved can easily track the history of a document and verify
                its authenticity. Also, Law Blocks AI is integrated with Digital
                Signature, which allows user to sign their document
                cryptographically by using secure blockchain technology.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FAQAccordion;

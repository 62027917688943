import React, {useState, useEffect} from "react";
import Header from "../componant/HeaderNew";
import {Button} from "@mui/material";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
} from "@mui/material";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
import Footer from "../Footer";
import {Helmet} from "react-helmet";
// import Bgvedio from "../Image/globe-fiat-block.mp4";

import FAQAccordion from "../componant/FAQAccordionProps";

function Home() {
  const [isScrolled, setIsScrolled] = useState(false);
  // useEffect(() => {
  //   function handleScroll() {
  //     const fourthSection = document.querySelector("#section-4");
  //     const fourthSectionTop = fourthSection.offsetTop;
  //     const currentScrollPosition = window.pageYOffset + window.innerHeight;
  //     if (currentScrollPosition >= fourthSectionTop) {
  //       setIsScrolledToFourthSection(true);
  //     } else {
  //       setIsScrolledToFourthSection(false);
  //     }
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  useEffect(() => {
    function handleScroll() {
      const fourthSection = document.querySelector("#section-4");
      const header = document.querySelector("header");
      const {top} = fourthSection.getBoundingClientRect();
      const headerHeight = header.offsetHeight;
      setIsScrolled(top <= headerHeight);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const faqs = [
    {
      question: "What is a Digital Signature?",
      answer:
        "A Digital Signature is an electronic form of a signature that verifies the authenticity and integrity of a digital document. It provides a secure and legally binding way to sign documents electronically, ensuring that the signer is who they claim to be and that the document has not been altered.",
    },
    {
      question: "How does Law Blocks AI ensure the security of my documents?",
      answer:
        "Law Blocks AI ensures the security of your documents through end-to-end encryption, multi-factor authentication, and an audit trail. End-to-end encryption protects your data from unauthorized access, while multi-factor authentication verifies the identity of each signer. The audit trail provides a comprehensive record of the signing process, ensuring transparency and accountability.",
    },
    {
      question: "Is it easy to upload documents on Law Blocks AI?",
      answer:
        "Yes, the process is straightforward and user-friendly, ensuring even those with minimal technical expertise can easily upload documents.",
    },
    {
      question: "How secure are the documents stored on the blockchain?",
      answer:
        "Documents are encrypted and stored with an immutable record, ensuring they are tamper-proof and secure.",
    },
    {
      question: "Can I access my documents anytime?",
      answer:
        "Yes, documents stored on Law Blocks AI can be accessed quickly and efficiently, from anywhere in the world.",
    },
    {
      question: "What types of documents can be stored?",
      answer:
        "Law Blocks AI supports various file formats, making it suitable for legal contracts, medical records, financial documents, and more.",
    },
    {
      question: "Is there customer support available?",
      answer:
        "Yes, Law Blocks AI offers comprehensive support to assist with any questions or issues.",
    },
  ];

  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const navigate = () => {
    window.open("https://market.lawblocks.io/login", "_blank");
  };

  return (
    <div className={isScrolled ? "header scrolled" : "header color_white"}>
      <Header />
      <Helmet>
        <title>
          Contribute as a Mediator with Law Blocks AI - Flexible and Efficient
          Mediation
        </title>
        <meta
          property="og:title"
          content="Contribute as a Mediator with Law Blocks AI - Flexible and Efficient Mediation"
        />
        <meta
          property="og:description"
          content="Join Law Blocks AI as a mediator and contribute to resolving disputes efficiently. Our platform connects clients with expert mediators, offering a streamlined, cost-effective, and confidential mediation process enhanced by AI technology."
        />
        {/* <!-- Uncomment and replace with actual image URL if available -->
<!-- <meta
  property="og:image"
  content="https://lawblocks.io/assets/mediator-thumbnail.jpg"
/> --> */}
        <meta
          property="og:url"
          content="https://lawblocks.io/contribute-mediator"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Contribute as a Mediator with Law Blocks AI - Flexible and Efficient Mediation"
        />
        <meta
          name="twitter:description"
          content="Discover how you can contribute as a mediator with Law Blocks AI. Our platform provides a modern, AI-powered solution for mediation, connecting clients with expert mediators for a cost-effective and confidential resolution of disputes."
        />
        {/* <!-- Uncomment and replace with actual image URL if available -->
<!-- <meta
  name="twitter:image"
  content="https://lawblocks.io/assets/mediator-thumbnail.jpg"
/> --> */}
      </Helmet>
      <main>
        {/* <SectionWithVideoBackground /> */}
        <section
          className="section_banner_hero new_Section_AIdoc uploaddocs  didgtal_hero bg_image_mediaor"
          id="section-4"
        >
          {/* <div className="purble"></div>
          <div className="yellow"></div>
          <div className="green"></div> */}

          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={6}>
                <div className="hero_seaction_law align-items-start ">
                  <h1
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-left mb-2"
                  >
                    Contribute as a Mediator
                  </h1>

                  <p
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-left m-0"
                  >
                    Join Law Blocks AI as a legal mediator and turn your
                    expertise into a rewarding experience.
                  </p>
                  <Button
                    onClick={navigate}
                    className="laight_button border_radious_button"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                  >
                    Get Started
                  </Button>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={6}
                className="d-flex align-items-center justify-content-center"
              ></Grid>
            </Grid>
          </Container>
        </section>
        <section className="section_defualt ">
          <Container maxWidth="lg">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={6}
                  className="d-flex align-item-center"
                >
                  <div
                    className="imag_widtjas image_contain_digital"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <img
                      src={require("../../Image/banner/mediaotion2.png")}
                      className="img-fluid"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="overvide align-items-start"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2>Overview of Mediation</h2>
                    <p className="text-justify">
                      Mediation offers a non-contentious alternative to
                      litigation by fostering negotiation between parties,
                      saving time and costs while preserving confidentiality.
                      Law Blocks AI enhances this process with advanced
                      technology, connecting users to top mediators across
                      various legal fields for efficient dispute resolution.
                    </p>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>

        <section className="section_defualt bg-gray_section pt-3 pb-4">
          <Container maxWidth="xl" className="mt-5">
            <div className="padding_adjust">
              <Grid container spacing={3} className="justify-content-center">
                <Grid item xs={12} md={12} lg={7}>
                  <div
                    className="overvide align-items-center pl-5 pl-sm-0"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2 className="text-center">
                      Benefits of Law Blocks AI Mediation
                    </h2>
                    <p className="text-center">
                      Law Blocks AI combines expert legal mediators with
                      cutting-edge AI tools to ensure faster, fairer
                      outcomes—without the cost and stress of court proceedings.
                      Key benefits include
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <ul class="benifitlaw-list tranc_xlass justify-content-center">
                    <li>
                      <div class="circle">1</div>
                      <div class="text">Efficient Online Mediation</div>
                    </li>
                    <li>
                      <div class="circle">2</div>
                      <div class="text">Expert Mediator Selection</div>
                    </li>
                    <li>
                      <div class="circle">3</div>
                      <div class="text">Cost-Effective Solutions</div>
                    </li>
                    <li>
                      <div class="circle">4</div>
                      <div class="text">AI-Powered Insights</div>
                    </li>
                    <li>
                      <div class="circle">5</div>
                      <div class="text">Confidential and Secure</div>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section
          className="section_defualt pt-3 pb-4"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={6}>
                <div class="features-container pl-0 pr-0">
                  <div class="feature-box ml-0 flex_align_werp">
                    <div className="circule_radioue">
                      <img
                        src={require("../../Image/banner/login.png")}
                        alt=""
                      />
                    </div>
                    <h3 className="text-center">Sign Up and Log In</h3>
                  </div>

                  <div class="feature-box flex_align_werp">
                    <div className="circule_radioue">
                      <img
                        src={require("../../Image/banner/lawer.png")}
                        alt=""
                      />
                    </div>
                    <h3 className="text-center">Choose Mediation Option</h3>
                  </div>
                  <div class="feature-box flex_align_werp ml-0">
                    <div className="circule_radioue">
                      <img
                        src={require("../../Image/banner/chat.png")}
                        alt=""
                      />
                    </div>
                    <h3 className="text-center">Choose Mediator Profile</h3>
                  </div>
                  <div class="feature-box  flex_align_werp">
                    <div className="circule_radioue">
                      <img
                        src={require("../../Image/banner/submit.png")}
                        alt=""
                      />
                    </div>
                    <h3 className="text-center">Send Request</h3>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={12} lg={6}>
                <div
                  className="overvide align-items-start"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <h2 className="text-left">
                    Process of Mediation in <br />
                    Law Blocks AI
                  </h2>
                  <p>
                    The mediation process at Law Blocks AI is designed with
                    simplicity and user accessibility in mind. We understand
                    that legal procedures can often feel overwhelming, so we've
                    streamlined our platform to make it easy for anyone to use.
                    Here’s a step-by-step breakdown of how mediation works
                    within Law Blocks AI
                  </p>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className="section_defualt  why_digilat bag_image_lawer  bg-gray_section">
          <Container maxWidth="lg">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="overvide align-items-start "
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2>Choose Your Mediator</h2>
                    <p className="text-justify">
                      Law Blocks AI offers a directory of skilled mediators with
                      expertise in various legal areas. Filter by
                      specialization, view profiles, and user reviews to find
                      the right match. Enjoy flexible scheduling and faster
                      resolutions on our online platform.
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className=" "
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <img
                      src={require("../../Image/banner/lawer_medioar.png")}
                      className="img-fluid bottin_imag_law"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section className="section_defualt pt-3 pb-5">
          <Container maxWidth="lg" className="mt-5">
            <div className="padding_adjust">
              <Grid container spacing={3} className="justify-content-center">
                <Grid item xs={12} md={12} lg={8}>
                  <div
                    className="overvide align-items-center"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2 className="text-center">Contribute as a Mediator</h2>
                    <p className="text-center">
                      Are you an experienced attorney looking to contribute to
                      the world of online mediation? Law Blocks AI offers you
                      the opportunity to join our mediator panel, where you can
                      help parties resolve disputes in a more amicable and
                      efficient manner.
                    </p>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <ul class="benifitlaw-list justify-content-center color_gray_card">
                    <li>
                      <div class="circle">1</div>
                      <div class="text">Sign Up as an Advocate</div>
                    </li>
                    <li>
                      <div class="circle">2</div>
                      <div class="text">Create Your Profile</div>
                    </li>
                    <li>
                      <div class="circle">3</div>
                      <div class="text"> Begin Mediation</div>
                    </li>
                  </ul>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section className="section_defualt  why_digilat bg-gray_section">
          <Container maxWidth="lg">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="overvide align-items-start "
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2>Why Law Blocks AI for Mediation?</h2>
                    <p>
                      Law Blocks AI enhances online dispute resolution with
                      AI-powered mediation for faster, more accurate case
                      insights. Our user-friendly platform streamlines the
                      process, enabling effective communication and efficient
                      resolutions. Whether you're a mediator or a party in a
                      dispute, Law Blocks AI offers a flexible solution that
                      simplifies mediation.
                    </p>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={6}
                  className="d-flex align-items-center"
                >
                  <div
                    className=" "
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <img
                      src={require("../../Image/banner/whymediator.png")}
                      className="img-fluid"
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
      </main>
      <div></div>
      <Footer />
    </div>
  );
}

export default Home;

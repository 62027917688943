import React, {useState} from "react";
import {
  Container,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import {Add} from "@mui/icons-material";

const FAQAccordion = ({faqs}) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {faqs.map((faq, index) => (
            <Accordion
              key={index}
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              style={{
                marginBottom: "16px",
                backgroundColor: "#f9f9f9",
                boxShadow: "none",
                minHeight: "70px",
                borderRadius: "5px",
                overflow: "hidden",
              }}
              className="card_dark darkPrice Faq_card"
            >
              <AccordionSummary
                expandIcon={
                  <Add
                    style={{
                      transform:
                        expanded === `panel${index}`
                          ? "rotate(90deg)"
                          : "rotate(0deg)",
                    }}
                  />
                }
              >
                <Typography className="text_faq">{faq.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{faq.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default FAQAccordion;

import React, {useState} from "react";
import {
  Container,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import {Add} from "@mui/icons-material";

const FAQAccordion = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            style={{
              marginBottom: "16px",
              backgroundColor: "#f9f9f9",
              boxShadow: "none",
              minHeight: "70px",
              borderRadius: "5px",
              overflow: "hidden",
            }}
            className="card_dark darkPrice Faq_card"
          >
            <AccordionSummary
              expandIcon={
                <Add
                  style={{
                    transform:
                      expanded === "panel1" ? "rotate(90deg)" : "rotate(0deg)",
                  }}
                />
              }
            >
              <Typography className="text_faq">
                How customizable are the templates?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Our templates can be fully customized to fit your specific legal
                needs. You can modify existing templates or create new ones
                tailored to your requirements using our AI Legal Template
                Creation feature.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            style={{
              marginBottom: "16px",
              backgroundColor: "#f9f9f9",
              boxShadow: "none",
              minHeight: "70px",
              borderRadius: "5px",
              overflow: "hidden",
            }}
            className="card_dark darkPrice Faq_card"
          >
            <AccordionSummary
              expandIcon={
                <Add
                  style={{
                    transform:
                      expanded === "panel2" ? "rotate(90deg)" : "rotate(0deg)",
                  }}
                />
              }
            >
              <Typography className="text_faq">Is my data secure?</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Yes, we use state-of-the-art encryption to protect your data and
                ensure your documents are secure.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            style={{
              marginBottom: "16px",
              backgroundColor: "#f9f9f9",
              boxShadow: "none",
              minHeight: "70px",
              borderRadius: "5px",
              overflow: "hidden",
            }}
            className="card_dark darkPrice Faq_card"
          >
            <AccordionSummary
              expandIcon={
                <Add
                  style={{
                    transform:
                      expanded === "panel3" ? "rotate(90deg)" : "rotate(0deg)",
                  }}
                />
              }
            >
              <Typography className="text_faq">
                What types of documents can I create?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                You can create a wide range of legal documents, including
                contracts, agreements, notices, and letters, across various
                industries and legal scenarios.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
            style={{
              marginBottom: "16px",
              backgroundColor: "#f9f9f9",
              boxShadow: "none",
              minHeight: "70px",
              borderRadius: "5px",
              overflow: "hidden",
            }}
            className="card_dark darkPrice Faq_card"
          >
            <AccordionSummary
              expandIcon={
                <Add
                  style={{
                    transform:
                      expanded === "panel4" ? "rotate(90deg)" : "rotate(0deg)",
                  }}
                />
              }
            >
              <Typography className="text_faq">
                How often are the templates updated?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Our templates are continuously updated to reflect the latest
                legal standards and regulatory requirements, ensuring your
                documents remain compliant.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
            style={{
              marginBottom: "16px",
              backgroundColor: "#f9f9f9",
              boxShadow: "none",
              minHeight: "70px",
              borderRadius: "5px",
              overflow: "hidden",
            }}
            className="card_dark darkPrice Faq_card"
          >
            <AccordionSummary
              expandIcon={
                <Add
                  style={{
                    transform:
                      expanded === "panel5" ? "rotate(90deg)" : "rotate(0deg)",
                  }}
                />
              }
            >
              <Typography className="text_faq">
                Do I need technical expertise to use Law Blocks AI?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                No, our platform is designed to be user-friendly and easy to
                navigate, even for those with no technical expertise. Guided
                workflows and an intuitive interface make document creation
                simple and straightforward.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FAQAccordion;

import React from "react";
import {Button} from "@mui/material";
import precnet from "../Image/Docs/Law_Blocks_PPT.pdf";
import whitePaper from "../Image/Docs/Copy of LawBlocks_Whitepaper_New.pdf";
import pitch from "../Image/Docs/pitch_deck_Law_Blocks.pdf";

function Footer() {
  return (
    <>
      <footer>
        <div className="container-fluid pl-5 pr-5">
          <div className="row">
            <div className="col-lg-4">
              <div className="logo_section_f">
                <img
                  src={require("../Image/law-Blocks-logo-dark.png")}
                  className="whiteshow"
                />
                <img
                  src={require("../Image/banner/dark_logo.png")}
                  className="blackshow"
                />

                <p>
                  Upgrade Your legal practice with Law Blocks AI, <br />
                  Powered by XDC Blockchain Network
                </p>

                <ul className="social mt-4">
                  <li>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/lawblocks/"
                    >
                      <i className="bi bi-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://x.com/lawblockstoken">
                      <i className="bi bi-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://t.me/LawBlocks_updates">
                      <i className="ri-telegram-fill"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://medium.com/@lawblocks">
                      <i className="ri-medium-fill"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.reddit.com/user/LawBlocks/"
                    >
                      <i className="ri-reddit-fill"></i>
                    </a>
                  </li>
                  <li>
                    <a target="_blank" href="https://github.com/LawBlocks">
                      <i className="ri-github-fill"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/lawblocks.legal.tech.community/"
                    >
                      <i className="bi bi-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/law_blocks_ai/"
                    >
                      <i className="bi bi-instagram"></i>
                    </a>
                  </li>

                  <li>
                    <a
                      target="_blank"
                      href="https://api.whatsapp.com/send/?phone=8104453315"
                    >
                      <i className="bi bi-whatsapp"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.youtube.com/@lawblocks2982"
                    >
                      <i class="ri-youtube-fill"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row">
                <div className="col-lg-3">
                  <div className="link_section">
                    <h4>Company</h4>
                    <ul>
                      <li>
                        <a href="/about-us">About Law Blocks AI</a>
                      </li>
                      <li>
                        <a href="/our-team">About Team</a>
                      </li>

                      <li>
                        <a href={whitePaper}>Whitepaper</a>
                      </li>
                      <li>
                        <a href={precnet} target="_blank">
                          Presentation{" "}
                        </a>
                      </li>
                      <li>
                        <a href={pitch} target="_blank">
                          Pitch Deck{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="link_section">
                    <h4>Other Links</h4>
                    <ul>
                      <li>
                        <a href="/">Community Support</a>
                      </li>
                      <li>
                        <a href="/blog">Blog</a>
                      </li>

                      <li>
                        <a href="/">Download Brand Assets</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="link_section">
                    <h4>Tokenomics</h4>
                    <ul>
                      <li>
                        <a href="/lbt-supported-wallets-exchanges">
                          Wallets & Exchanges
                        </a>
                      </li>
                      <li>
                        <a href="https://tokeninsight.com/en/coins/law-blocks/tokenomics">
                          Tokenomics
                        </a>
                      </li>

                      <li>
                        <a href="/">Our Partners</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="link_section">
                    <h4>Coming Soon</h4>
                    <ul>
                      <li>
                        <a href="/virtual-courtroom">
                          Virtual Courts in Metaverse
                        </a>
                      </li>
                      <li>
                        <a href="/alternative-dispute-resolution">ADR</a>
                      </li>

                      <li>
                        <a href="/">Dispute Resolution</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="copywrigt">
                <p>
                  {" "}
                  All rights reserved. Copyright 2024 © LawBlocks, All Right
                  Reserved
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div id="myModal" class="modal fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Buy Ticket</h4>
              <button type="button" class="close" data-dismiss="modal">
                &times;
              </button>
            </div>
            <div class="modal-body">
              <div className="ticket_section">
                <div className="input_form_tivket">
                  <p>
                    Buy<span>Ticket</span>
                  </p>
                  <input type="number" placeholder="" />
                  <div className="amount_bust">~ 0.001 BUSD</div>
                </div>
                <div className="amount_content">
                  <p>
                    Cost(BUSD)<span>0.001 BUSD</span>
                  </p>
                  <div className="youpay">
                    <p>
                      You Pay <span> ~ 0 BUSD</span>
                    </p>
                  </div>
                </div>
                <Button className="header_btn">Connect Wallet</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;

import React, {useState, useEffect} from "react";
import Header from "../componant/HeaderNew";
import {Button} from "@mui/material";
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardActions,
  Typography,
  Box,
} from "@mui/material";

import Footer from "../Footer";

import {Helmet} from "react-helmet";

function Home() {
  const posts = [
    {
      slug:
        "marketplace-for-lawyers-a-new-era-of-legal-service-delivery-law-blocks-ai",
      title: 
        "Marketplace for Lawyers: A New Era of Legal Service Delivery - Law Blocks AI",
      category: "Marketplace for Lawyers",
      date: "July 12, 2024",
      description:
        "Explore how Law Blocks AI is transforming legal service delivery with a new marketplace for lawyers. Discover the benefits of AI-powered legal solutions, streamlined processes, and enhanced client-lawyer interactions.",

      likes: 0,
      imagePath:
        "https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/Marketplace_slfuz1.jpg",
    },
    {
      slug: "benefits-of-ai-chat-for-legal-professionals-and-clients",
      title: "The Benefits of AI Chat for Legal Professionals and Clients",
      category: "Global Information AI",
      date: "July 12, 2024",
      description:
        "Discover the advantages of AI chat for legal professionals and clients with Law Blocks AI. Learn how AI-powered chat enhances communication, efficiency, and client satisfaction in the legal industry.",

      likes: 0,
      imagePath:
        "https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/ai_chat_sisdav.jpg",
    },
    {
      slug: "how-digital-signature-features-work-on-law-blocks-ai",
      title: "How Digital Signature Features Work on Law Blocks AI",
      category: "Digital Signature",
      date: "July 12, 2024",
      description:
        "Learn how Law Blocks AI enhances digital signature features to provide secure, efficient, and legally binding document signing. Explore the benefits of digital signatures in streamlining legal processes and ensuring document integrity.",

      likes: 0,
      imagePath:
        "https://res.cloudinary.com/decbm52pl/image/upload/v1720805703/ds_thnxrv.jpg",
    },
  ];

  const [isScrolled, setIsScrolled] = useState(false);
  // useEffect(() => {
  //   function handleScroll() {
  //     const fourthSection = document.querySelector("#section-4");
  //     const fourthSectionTop = fourthSection.offsetTop;
  //     const currentScrollPosition = window.pageYOffset + window.innerHeight;
  //     if (currentScrollPosition >= fourthSectionTop) {
  //       setIsScrolledToFourthSection(true);
  //     } else {
  //       setIsScrolledToFourthSection(false);
  //     }
  //   }

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);
  useEffect(() => {
    function handleScroll() {
      const fourthSection = document.querySelector("#section-4");
      const header = document.querySelector("header");
      const {top} = fourthSection.getBoundingClientRect();
      const headerHeight = header.offsetHeight;
      setIsScrolled(top <= headerHeight);
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    margin: 10,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  const navigate = () => {
    window.open("https://market.lawblocks.io/login", "_blank");
  };

  return (
    <div className={isScrolled ? "header scrolled" : "header"}>
      <Header />
      <Helmet>
        <title>About Us | Law Blocks AI - Simplify Your Legal Workflows</title>
        <meta
          property="og:title"
          content="About Us | Law Blocks AI - Simplify Your Legal Workflows"
        />
        <meta
          property="og:description"
          content="Learn about Law Blocks AI, a leading legal tech ecosystem that leverages AI and blockchain to simplify legal tasks. Discover our mission to revolutionize legal processes and our vision to transform the legal industry with cutting-edge technology."
        />
        {/* <meta
          property="og:image"
          content="https://lawblocks.io/assets/about-us-thumbnail.jpg"
        /> */}
        <meta property="og:url" content="https://lawblocks.io/about-us" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <main>
        {/* <SectionWithVideoBackground /> */}
        <section
          className="section_banner_hero new_Section_AIdoc about_done"
          id="section-4"
        >
          {/* <div className="purble"></div>
          <div className="yellow"></div>
          <div className="green"></div> */}

          <Container maxWidth="lg">
            <Grid
              container
              spacing={3}
              className="justify-content-center"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="300"
            >
              <Grid item xs={12} md={12} lg={6}>
                <div className="hero_seaction_law align-items-center">
                  <h1
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-center mb-2"
                  >
                    About Us
                  </h1>

                  <p
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    className="text-center m-0"
                  >
                    Simplify your legal workflows with AI-powered features of
                    Law Blocks AI.
                  </p>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section
          className="section_defualt  select_about_image"
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="300"
        >
          <img
            src={require("../../Image/banner/about_image.webp")}
            className="img-fluid"
          />
        </section>
        <section className="section_defualt mt-5 ">
          <Container maxWidth="lg">
            <div className="padding_adjust">
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={10}>
                  <div className="overvide card_about">
                    <div className="width_70">
                      <h2>Who We Are?</h2>
                      <p>
                        Law Blocks AI is a leading legal tech ecosystem designed
                        to simplify and automate legal tasks. Our platform
                        leverages advanced AI and blockchain technology to
                        deliver reliable and efficient legal solutions. Whether
                        you need to draft legal documents, manage contracts, or
                        secure your data, Law Blocks AI has you covered.
                        Established in 2022 by our visionary co-founders Ashish
                        Kumar Baphana and Hitomi Baphana.
                      </p>
                      <img
                        src={require("../../Image/banner/border_abot_imag.webp")}
                        className="img-fluid postion_imag_rel"
                      />
                    </div>
                  </div>
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="overvide card_about"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-delay="300"
                  >
                    <div className="">
                      <h2>Our Mission</h2>
                      <p>
                        Our mission at Law Blocks AI is to revolutionize legal
                        processes with AI and blockchain solutions. We strive to
                        develop tools that enhance efficiency, accuracy, and
                        security. We are committed to making advanced legal
                        technology accessible and supporting legal professionals
                        and common people every step of the way.
                      </p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                  <div
                    className="overvide card_about"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-delay="300"
                  >
                    <div className="">
                      <h2>Our Vision</h2>
                      <p>
                        At Law Blocks AI, our vision is to transform the legal
                        industry through advanced AI and blockchain
                        technologies. We aim to make legal services more
                        efficient, precise, and secure, empowering lawyers to
                        focus on their clients. By driving innovation, we set
                        new standards in legal tech.
                      </p>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <div
                    class="stats-container"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-delay="300"
                  >
                    <div class="stat-box">
                      <h2>120600+</h2>
                      <p>Contracts Uploaded</p>
                    </div>
                    <div class="stat-box">
                      <h2>1000+</h2>
                      <p>No of Users</p>
                    </div>
                    <div class="stat-box">
                      <h2>600+</h2>
                      <p>No of Arbitrators & Mediators</p>
                    </div>
                    <div class="stat-box">
                      <h2>300+</h2>
                      <p>No of Lawyer Registered</p>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>

        <section className="section_defualt background_row_ss">
          <Container maxWidth="xl">
            <div
              className="padding_adjust"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-delay="300"
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={12}>
                  <div
                    className="overvide align-items-center"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                  >
                    <h2 className="text-center"> Features of Law Blocks AI</h2>
                   
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={4}
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="300"
                >
                  <div className="overvide align-items-start">
                    <div className="fetuer_section flex-wrap flec_row_ds">
                      <div className="half_width w-100">
                        <div>
                          <span>1</span>
                        </div>
                        <div className="content_section_">
                          <h4>AI Legal Document Generator</h4>
                          <p>
                            Create precise legal documents tailored to specific
                            jurisdictions with ease.
                          </p>
                        </div>
                      </div>
                      <div className="half_width w-100">
                        <div>
                          <span>2</span>
                        </div>{" "}
                        <div className="content_section_">
                          <h4>Legal Document Generating AI</h4>
                          <p>
                            Automate document creation to save time and reduce
                            errors.
                          </p>
                        </div>
                      </div>
                      <div className="half_width w-100">
                        <div>
                          <span>3</span>
                        </div>{" "}
                        <div className="content_section_">
                          <h4>Legal Document Creation AI</h4>
                          <p>
                            Automate document creation to save time and reduce
                            errors.
                          </p>
                        </div>
                      </div>
                      <div className="half_width w-100">
                        <div>
                          <span>4</span>
                        </div>{" "}
                        <div className="content_section_">
                          <h4>AI Legal Template Creation</h4>
                          <p>
                            Access a library of templates designed to meet
                            diverse legal needs.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={4}
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="300"
                >
                  <div
                    className="imag_widtjas"
                    data-aos="zoom-in"
                    data-aos-duration="500"
                    data-aos-delay="200"
                    onClick={navigate}
                  >
                    <img
                      src={require("../../Image/banner/ferutr_sectk.webp")}
                      className="img-fluid cursor_point"
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={4}
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="300"
                >
                  <div className="overvide align-items-start">
                    <div className="fetuer_section flex-wrap flec_row_ds">
                      <div className="half_width w-100">
                        <div>
                          <span>5</span>
                        </div>
                        <div className="content_section_">
                          <h4>AI Legal Agreement Generator</h4>
                          <p>
                            Generate comprehensive legal agreements quickly and
                            accurately.
                          </p>
                        </div>
                      </div>
                      <div className="half_width w-100">
                        <div>
                          <span>6</span>
                        </div>{" "}
                        <div className="content_section_">
                          <h4>Digital Signatures</h4>
                          <p>
                            Sign documents securely with our robust digital
                            signature capabilities.
                          </p>
                        </div>
                      </div>
                      <div className="half_width w-100">
                        <div>
                          <span>7</span>
                        </div>{" "}
                        <div className="content_section_">
                          <h4>Blockchain Storage</h4>
                          <p>
                            Store your documents on the blockchain for enhanced
                            security and transparency.
                          </p>
                        </div>
                      </div>
                      <div className="half_width w-100">
                        <div>
                          <span>8</span>
                        </div>{" "}
                        <div className="content_section_">
                          <h4>AI Chat for Legal Assistance</h4>
                          <p>
                             Get instant answers and support through our
                            AI-powered chat feature.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Container>
        </section>
        <section
          className="section_defualt "
          data-aos="fade-up"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <div
                  className="overvide align-items-center"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  <h2 className="text-center">Why Choose Law Blocks AI?</h2>
                </div>
              </Grid>
            </Grid>
            <div class="features-container">
              <div class="feature-box">
                <div className="circule_radioue">
                  <img
                    src={require("../../Image/banner/inovative.png")}
                    alt=""
                  />
                </div>
                <h3>Innovative Technology</h3>
                <p>
                  Stay ahead with the latest in AI and blockchain integration.
                </p>
              </div>
              <div class="feature-box">
                <div className="circule_radioue">
                  <img src={require("../../Image/banner/uiser.png")} alt="" />
                </div>
                <h3>User-Friendly Interface</h3>
                <p>
                  Our platform is designed to be intuitive and easy to use, even
                  for those with no technical expertise.
                </p>
              </div>
              <div class="feature-box">
                <div className="circule_radioue">
                  <img
                    src={require("../../Image/banner/shield-check.png")}
                    alt=""
                  />
                </div>
                <h3>Security and Compliance</h3>
                <p>
                  Benefit from the highest standards of security and legal
                  compliance.
                </p>
              </div>
              <div class="feature-box">
                <div className="circule_radioue">
                  <img
                    src={require("../../Image/banner/user-headset.png")}
                    alt=""
                  />
                </div>
                <h3>24/7 Support</h3>
                <p>
                  Our team is always here to assist you with any questions or
                  issues.
                </p>
              </div>
            </div>
          </Container>
        </section>
        <section className="section_defualt">
          <Container maxWidth="lg">
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12} className="">
                <div
                  className=" overvide headeing_main align-items-center"
                  data-aos="fade-up"
                  data-aos-duration="500"
                  data-aos-delay="200"
                >
                  {/* <span className="lable_badge">Our Blog</span> */}
                  <h2 className="text-captailz text-center mb-2">
                    Get Started
                  </h2>
                  <p className="text-center">
                    Join the revolution in legal tech today. Explore the
                    powerful features of Law Blocks AI <br /> and see how we can
                    help you transform your legal practice.
                  </p>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className=" mb-4 d-flex justify-content-center pt-0"
              >
                <Button
                  onClick={navigate}
                  className="laight_button border_radious_button mt-0"
                >
                  Get Started
                </Button>
              </Grid>
            </Grid>
          </Container>
        </section>
      </main>
      <div></div>
      <Footer />
    </div>
  );
}

export default Home;
